(function($) {

    /*------------------------------------------------------------------------*/
    /*  Document ready
    /*------------------------------------------------------------------------*/

    $(document).ready( function() {

        console.log( "Init" );

        // Init fastclick for touch
        FastClick.attach(document.body);

        // Init all default functions
        rodeskDefaults.init();

        // Init matchHeight plugin
        var matchHeightElements = ['.js-equalize', '.o-card__image', '.c-card__heading', '.c-card__text'];
        rodeskDefaults.matchHeight( matchHeightElements );

        $('input, textarea').placeholder({ customClass: 'c-input__placeholder' });

        if ($(".map").length > 0) {
            mapinitialize();
        }

        if ( $( window ).scrollTop() > 100 ) {

            $( '.c-header' ).addClass('is-scrolled');

        } else if ( $( window ).scrollTop() < 100 ) {

            $( '.c-header' ).removeClass('is-scrolled');

        }

        validateForm();

        // var grid = document.querySelector( '.js-masonry' );

        // console.log(grid);

        // if( $( grid ).length > 0 ) {
        //     salvattore.registerGrid(grid);
        //     salvattore.rescanMediaQueries();
        // }
    });

    $(window).load( function() {
        var imgLiquidElements = $( ".js-image-liquid" );
        rodeskDefaults.imgLiquidCustom(imgLiquidElements);
    });

    /*------------------------------------------------------------------------*/
    /*  Resize events
    /*------------------------------------------------------------------------*/

    // Do the window resize with a debounce
    // All your resize related bindings here
    $(window).on("resize", $.debounce(100, function() {
        if ( $( window ).scrollTop() > 100 ) {

            $( '.c-header' ).addClass('is-scrolled');

        } else if ( $( window ).scrollTop() < 100 ) {

            $( '.c-header' ).removeClass('is-scrolled');

        }
    }));

    /*------------------------------------------------------------------------*/
    /*  Scroll events
    /*------------------------------------------------------------------------*/

    // Do the scroll events resize with a throttle
    // All your scroll related bindings here
    $(window).on('scroll', $.throttle(100, function() {

        if ( $( window ).scrollTop() > 100 ) {

            $( '.c-header' ).addClass('is-scrolled');

        } else if ( $( window ).scrollTop() < 100 ) {

            $( '.c-header' ).removeClass('is-scrolled');

        }

    }));

    function mapinitialize() {

        var myLatLng = new google.maps.LatLng(52.098713, 5.034492);
        var mapCanvas = document.getElementById('map');

        var mapOptions = {
            center: myLatLng,
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        var map = new google.maps.Map(mapCanvas, mapOptions);

        var iconBase = '../wp-content/themes/harms-communicatie/assets/images/';
        var icons = {
            logo: {
                icon: iconBase + 'logo-small.png'
            }
        };

        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            icon: icons.logo.icon,
            title: 'Harms Communicatie'
        });

        marker.setMap(map);
    }

    $( '.o-form' ).submit(function( event ) {
        event.preventDefault();
    });

    function validateForm() {

        var $form = $( '.js-formvalidate' );

        if ( $form.length > 0 ) {

            form = $form.parsley({
                errorClass: 'is-error',
                succesClass: 'is-valid',
                classHandler: function(el) {
                    return el.$element.parent();
                }
            });

            window.Parsley.on('form:error', function() {
                this.$element.removeClass( 'is-valid' );
                this.$element.addClass( 'is-error' );
            });

            window.Parsley.on('form:success', function() {
                this.$element.find('.o-form__input').removeClass( 'is-error' );
                this.$element.addClass( 'is-valid' );
                sendForm( this.$element );
            });

            window.Parsley.on('field:validate', function() {
                if ( form.isValid()) {
                    this.$element.closest( '.js-formvalidate' ).removeClass( 'is-error' );
                } else {
                    this.$element.closest( '.js-formvalidate' ).addClass( 'is-error' );
                }
            });

        }
    }

    function sendForm( form ) {

        var firstname   =     $( '#firstname' ).val();
        var lastname    =     $( '#lastname' ).val();
        var mail        =     $( '#mail' ).val();
        var phone       =     $( '#phone' ).val();
        var message     =     $( '#message' ).val();
        var $form       =     $( form );

        $form.find( '.o-form__input' ).prop('disabled', true);
        $form.find( '.c-btn' ).prop('disabled', true).css({ opacity: 0.5, cursor: 'not-allowed' });

        $.ajax({
            type: 'POST',
            url: '../wp-content/themes/harms-communicatie/sendform.php',
            data: {
                firstname       :   firstname,
                lastname        :   lastname,
                mail            :   mail,
                phone           :   phone,
                message         :   message
            },

            error: function(xhr, status, error) {
                $form.find( '.o-form__input' ).prop('disabled', false);
                $form.find( '.c-btn' ).prop('disabled', false).css({ opacity: 1, cursor: 'pointer' });
                alert( error );
            },

            success: function(json, test) {
                $( "#firstname" ).val('');
                $( "#lastname" ).val('');
                $( "#mail" ).val('');
                $( "#phone" ).val('');
                $( "#message" ).val('');

                $form.remove();

                $( '.c-thankyou' ).css({
                    display: 'block'
                });
            }
        });
    }

    $( '.c-hamburger' ).on( 'click', function(){
        $( this ).toggleClass( 'is-open' );
        $( '.c-nav-main__list' ).toggleClass( 'is-open' );
    });

})(jQuery); // Fully reference jQuery after this point.
