/*------------------------------------------------------------------------*/
/*  Rodesk breakpoint functions
/*------------------------------------------------------------------------*/

/*
 * Use this JS module to define the actual SCSS breakpoint
 * This allowes to share SCSS breakpoints with javascript
 *
 * This function returns a string with the actual breakpoint
 * rodeskBreakpoints.getBreakpoint()
 *
 * This function checks  if a passed string matches the actual breakpoint
 * Returns true/false
 * rodeskBreakpoints.isBreakpoint( breakpoint )
 *
 */

var rodeskBreakpoints = (function () {

    /**
     * Remove the quotes from a string
     * @param  string   The string to be cleaned
     * @return string   The cleaned string wihtout quotes
     */
    var _removeQuotes = function(string) {

        if (typeof string === 'string' || string instanceof String) {
            string = string.replace(/[^a-zA-Z ]/g, "");
        }

        return string;
    };

    /**
     * Output the actual browser viewport breakpoint
     * @return  string  The actual breakpoint of the browser
     */
    var _getBreakpoint = function() {

        var style = null;

        if ( window.getComputedStyle && window.getComputedStyle(document.body, '::after') ) {
            style = window.getComputedStyle(document.body, '::after');
            style = style.content;
        } else {
            window.getComputedStyle = function(el) {
                this.el = el;
                this.getPropertyValue = function(prop) {
                    var re = /(\-([a-z]){1})/g;
                    if (re.test(prop)) {
                        prop = prop.replace(re, function () {
                            return arguments[2].toUpperCase();
                        });
                    }
                    return el.currentStyle[prop] ? el.currentStyle[prop] : null;
                };
                return this;
            };
            style = window.getComputedStyle(document.getElementsByTagName('head')[0]);
            style = style.getPropertyValue('font-family');
        }

        return _removeQuotes( style );

    };

    /**
     * Check if a breakpoints matches the actual browser viewport breakpoint
     * @param  string  breakpoint The breakpoint name we are checking
     * @return Boolean            Check if breakpoints matches with actual browser breakpoint
     */
    var _isBreakpoint = function( breakpoint ) {

        // @TODO find a better method / more dynamic for this
        var breakpoints = {
            small : [ 'small', 'compact', 'medium', 'large', 'wide', 'huge' ],
            compact: [ 'compact', 'medium', 'large', 'wide', 'huge' ],
            medium: [ 'medium', 'large', 'wide', 'huge' ],
            large: [ 'large', 'wide', 'huge' ],
            wide: [ 'wide', 'huge' ],
            huge: [ 'huge' ]
        };

        var actualBreakpoint = _getBreakpoint();

        if ( jQuery.inArray( actualBreakpoint, breakpoints[ breakpoint ] ) !== -1) {
            return true;
        }

        return false;

    };

    // Return an object exposed to the public
    return {

        /**
         * Make some private functions public
        */
        isBreakpoint: _isBreakpoint,
        getBreakpoint: _getBreakpoint

    };

})(jQuery); // Fully reference jQuery after this point.
